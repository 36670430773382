import React from "react"
import Layout from "../components/layout"
import Hero from "../components/index/hero"
import News from "../components/index/news"
import About from "../components/index/about"
import Banner from "../components/index/banner"
import Address from "../components/index/address"
import "../styles/main.scss"
import Menu from "../components/index/menu"

export default function Home() {
  return (
    <Layout>
      <Hero />
      <News />
      <About />
      <Banner />
      <Menu />
      <Address />
    </Layout>
  )
}
