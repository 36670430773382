import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import heroStyles from "./hero.module.scss"

export default function Hero() {
  const data = useStaticQuery(graphql`
    query HeroImage {
      image: file(relativePath: { eq: "banner2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3080, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      face: file(relativePath: { eq: "facebook-banner2.png" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      insta: file(relativePath: { eq: "instagram-banner2.png" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage
      className={heroStyles.bg}
      fluid={data.image.childImageSharp.fluid}
    >
      <div id="inicio" className={heroStyles.hero}>
        <a
          href="https://www.facebook.com/Crispy-Crab-103514688151103"
          target="_blank"
          rel="noreferrer"
        >
          <Img
            className={heroStyles.face}
            fixed={data.face.childImageSharp.fixed}
            alt="facebook"
            style={{ position: "absolute" }}
          />
        </a>
        <a
          href="https://www.instagram.com/crispycrab_pereira/?hl=es-la"
          target="_blank"
          rel="noreferrer"
        >
          <Img
            className={heroStyles.insta}
            fixed={data.insta.childImageSharp.fixed}
            alt="instagram"
            style={{ position: "absolute" }}
          />
        </a>
      </div>
    </BackgroundImage>
  )
}
