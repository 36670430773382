import React from "react"
import Img from "gatsby-image"
import menuStyles from "./menu.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import menuPDF from "../../assets/menu.pdf"
import BackgroundImage from "gatsby-background-image"

export default function Menu() {
  const data = useStaticQuery(graphql`
    query MenuImage {
      background: file(relativePath: { eq: "fondo-menu.png" }) {
        childImageSharp {
          fluid(maxWidth: 3080, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      hamburguer: file(relativePath: { eq: "menu/hamburguesa.jpg" }) {
        childImageSharp {
          fixed(width: 220, height: 150) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      langostinos: file(relativePath: { eq: "menu/langostinos.jpg" }) {
        childImageSharp {
          fixed(width: 220, height: 150) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      perro: file(relativePath: { eq: "menu/perro.jpg" }) {
        childImageSharp {
          fixed(width: 220, height: 150) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      tacos: file(relativePath: { eq: "menu/tacos.jpg" }) {
        childImageSharp {
          fixed(width: 220, height: 150) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage fluid={data.background.childImageSharp.fluid}>
      <div id="menu" className={menuStyles.container}>
        <div className={menuStyles.content}>
          <h2>MENÚ</h2>
          <div className={menuStyles.products}>
            <div className={menuStyles.product}>
              <h2 className={menuStyles.title}>Cangre Burger</h2>
              <div className={menuStyles.imageContainer}>
                <Img
                  className={menuStyles.img}
                  fixed={data.hamburguer.childImageSharp.fixed}
                />
              </div>
            </div>

            <div className={menuStyles.product}>
              <h2 className={menuStyles.title}>Langostino Crab</h2>
              <div className={menuStyles.imageContainer}>
                <Img
                  className={menuStyles.img}
                  fixed={data.langostinos.childImageSharp.fixed}
                />
              </div>
            </div>

            <div className={menuStyles.product}>
              <h2 className={menuStyles.title}>Perro Camarón</h2>
              <div className={menuStyles.imageContainer}>
                <Img
                  className={menuStyles.img}
                  fixed={data.perro.childImageSharp.fixed}
                />
              </div>
            </div>

            <div className={menuStyles.product}>
              <h2 className={menuStyles.title}>Tacos Camarón</h2>
              <div className={menuStyles.imageContainer}>
                <Img
                  className={menuStyles.img}
                  fixed={data.tacos.childImageSharp.fixed}
                />
              </div>
            </div>
          </div>
          <div className={menuStyles.menuFileContainer}>
            <a
              className={menuStyles.menuFile}
              href={menuPDF}
              target="_blank"
              rel="noreferrer"
            >
              CONOCE NUESTRA CARTA
            </a>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}
