import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import addressStyles from "./address.module.scss"

export default function About() {
  const data = useStaticQuery(graphql`
    query AddressImage {
      image: file(relativePath: { eq: "fondo-testimonio.png" }) {
        childImageSharp {
          fluid(maxWidth: 3080, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage
      className={addressStyles.AddressImage}
      fluid={data.image.childImageSharp.fluid}
    >
      <div id="contacto" className={addressStyles.container}>
        <h2 className={addressStyles.title}>UBICACIÓN Y CONTACTO</h2>
        <div className={addressStyles.content}>
          <div className={addressStyles.info}>
            <div className={addressStyles.locationContainer}>
              <iframe
                title="Cerritos"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7951.52100976785!2d-75.7936183!3d4.8111355!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e387d0280a6f771%3A0xa6d26840c32239ed!2sGarden%20Mall%20Cerritos!5e0!3m2!1ses-419!2sco!4v1612912338700!5m2!1ses-419!2sco"
                height="450"
                allowFullScreen
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
            <div className={addressStyles.row}>
              <div>
                <h3>Ubicación</h3>
                <p>
                  Garden Mall Cerritos, Frente a Uniminuto, Barrio Galicia{" "}
                  <br />
                  Pereira, Risaralda <br />
                  Colombia
                </p>
              </div>
              <div>
                <h3>Horario</h3>
                <p>Lun - Dom, 11 am - 9 pm</p>
              </div>
              <div>
                <h3>Contacto</h3>
                <p>
                  <a href="tel:036205686862">(03) (6) 320 568 6862</a>
                </p>
              </div>
            </div>
          </div>

          <div className={addressStyles.info}>
            <div className={addressStyles.locationContainer}>
              <iframe
                title="Circunvalar"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1987.8946287429085!2d-75.68355676322936!3d4.806209957847059!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e38873c6ab09a37%3A0xe36d7e38dffa13fd!2sLa%20Terraza!5e0!3m2!1ses-419!2sco!4v1613077752413!5m2!1ses-419!2sco"
                height="450"
                allowFullScreen
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
            <div className={addressStyles.row}>
              <div>
                <h3>Ubicación</h3>
                <p>
                  Mall La Terraza, Avenida Circunvalar #4-63
                  <br />
                  Pereira, Risaralda <br />
                  Colombia
                </p>
              </div>
              <div>
                <h3>Horario</h3>
                <p>Lun - Dom, 11 am - 11 pm</p>
              </div>
              <div>
                <h3>Contacto</h3>
                <p>
                  <a href="tel:03363450926">(03) (6) 323 345 0926</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}
