import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import bannerStyles from "./banner.module.scss"

export default function Banner() {
  const data = useStaticQuery(graphql`
    query BannerImage {
      image: file(relativePath: { eq: "foto-paralaje.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3080, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage
      className={bannerStyles.bg}
      fluid={data.image.childImageSharp.fluid}
    >
      <div className={bannerStyles.hero}></div>
    </BackgroundImage>
  )
}
