import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import newsStyles from "./news.module.scss"
import _get from "lodash/get"

export default function News() {
  const [posts, setPosts] = useState([])

  const axios = require("axios").default

  useEffect(() => {
    axios
      .get(
        `https://graph.instagram.com/me/media?fields=id,caption,media_url,permalink,media_type&access_token=${process.env.GATSBY_INSTAGRAM_TOKEN}&limit=7`
      )
      .then(function (response) {
        setPosts(_get(response, "data.data"))
        console.log(posts)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  const data = useStaticQuery(graphql`
    query NewsImage {
      image: file(relativePath: { eq: "fondo-noticas.png" }) {
        childImageSharp {
          fluid(maxWidth: 3080, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage
      className={newsStyles.bg}
      fluid={data.image.childImageSharp.fluid}
    >
      <div id="posts" className={newsStyles.container}>
        <div className={newsStyles.content}>
          <h2>NOTICIAS Y EVENTOS</h2>
          <div className={newsStyles.posts}>
            {posts.map((item, i) => {
              if (item.media_type === "VIDEO") {
                return (
                  <div className={newsStyles.post} key={`post-${i}`}>
                    <iframe
                      className={newsStyles.img}
                      src={item.media_url}
                      frameborder="0"
                      width="300px"
                      height="300px"
                      title={`insta-video-${i}`}
                    ></iframe>
                    <div className={newsStyles.middle}>
                      <a
                        className={newsStyles.text}
                        href={item.permalink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Ver publicación
                      </a>
                    </div>
                  </div>
                )
              } else {
                return (
                  <div className={newsStyles.post} key={`post-${i}`}>
                    <img
                      className={newsStyles.img}
                      src={item.media_url}
                      alt={`Post instagram ${i}`}
                      width="300px"
                      height="300px"
                    />
                    <div className={newsStyles.middle}>
                      <a
                        className={newsStyles.text}
                        href={item.permalink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Ver publicación
                      </a>
                    </div>
                  </div>
                )
              }
            })}
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}
