import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import aboutStyles from "./about.module.scss"

export default function About() {
  const data = useStaticQuery(graphql`
    query AboutImage {
      image: file(relativePath: { eq: "fondo-somos.png" }) {
        childImageSharp {
          fluid(maxWidth: 3080, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage
      className={aboutStyles.AboutImage}
      fluid={data.image.childImageSharp.fluid}
    >
      <div className={aboutStyles.container} id="nosotros">
        <div className={aboutStyles.content}>
          <div className={aboutStyles.about}>
            <h2 className={aboutStyles.name}>¿QUIÉNES SOMOS?</h2>
            <p>
              Somos un restaurante de concepto urbano, fresco y playero, nuestra
              especialidad es la comida de mar en presentaciones novedosas al
              estilo ¨fast food¨ y resaltando los sabores del pacifico.
              <br />
              ¡Crispy Crab te va encantar!
            </p>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}
